.writingList{
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
}


.writingItem{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:1rem;
    background-color: var(--bg-color2);
    border-bottom: 0.5px var(--bg-color1    ) solid;
}
.writingItem:hover{
    background-color: var(--bg-color3);
    cursor: url('../assets/hover.png'),auto;
}