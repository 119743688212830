.link{
    text-decoration: none;
    color: var(--text-color6);
    font-weight:700
}

.link:hover{
    text-decoration: underline;
    color: #ff7744f0;
    font-weight:700
}

.col{
    max-width: min(864px, 100vw);
    display: flex;
    justify-content: flex-start;
    margin: 3rem 1rem 8rem 1rem;
}
.row{
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
}

.closeIcon{
    background-color: transparent;
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:0.5rem;
    padding:1px;
    border-radius: 0.25rem;
    border: 1px  solid var(--text-color5);
}

.closeIcon:hover{
    background-color: var(--text-color2);
}

.spinnerFirstOverlay{
    height:100vh;
    width:100vw;
    position:fixed;
    margin:auto;
    z-index: 1001;
    background:#121212;
    animation-iteration-count: 1;
    animation: fromtop1 ease-in 4s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

}

.spinnerSecondOverlay{
    height:100vh;
    width:100vw;
    position:fixed;
    margin:auto;
    z-index: 1002;
    margin-top: -1%;
    background:#2d2d2d;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation: fromtop2 ease-in 4.2s;
    -webkit-animation-fill-mode: forwards;
}

.spinnerMain{
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation: fade ease-in 3.2s;
    
    -webkit-animation-iteration-count:1;
    -webkit-animation: fade ease-in 3.2s;
    -webkit-animation-fill-mode: forwards;
}

.spinnerVideo{
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation: fade ease-in 2.6s;
    
    -webkit-animation-iteration-count:1;
    -webkit-animation: fade ease-in 2.6s;
    -webkit-animation-fill-mode: forwards;
}

@keyframes fade {
    0% {
       
        opacity: 100%;
    }
    80% {
    
        opacity: 100%;
    }
     100%{
        opacity: 0%;
     }
}


@keyframes fromtop1 {
    0% {
        height: 0%;
        opacity: 100%;
    }
    50% {
        height: 0%;
        opacity: 100%;
    }

    85% {
        height: 145%;
        opacity: 100%;
     }
     100%{
        height: 145%;
        opacity: 0%;
     }
}

@keyframes fromtop2 {
    0% {
        height: 0%;
        opacity: 100%;
    }
    60% {
        height: 0%;
        opacity: 100%;
    }

    85% {
        height: 145%;
        opacity: 100%;
     }
     100%{
        height: 145%;
        opacity: 0%;
     }
}


.littleThingsCard{
   
    border-radius: 0.5rem;
    margin-bottom:2rem;
    padding: 1rem;
    display: grid;
    flex-direction: row;
    gap:0.6rem;
    
    
}

.littleThingsCard:hover{
    background-color: var(--bg-color3);
}




.littlethings-card-grid{
    display: flex;
    flex-wrap: wrap;
}



.littlethings-cardGroup{
    background-color: white;
    border-radius: 0.54rem;
  
    margin: 10px;

    width: calc(33.33% - 20px); /* For three cards */
    transition: transform 0.2s; /* Smooth hover effect */

    
}

.littlethings-card{
    background-color: var(--bg-color4);
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: box-shadow 0.3s ease;
    border: 0.5px var(--bg-color5) solid;
}

.littlethings-card:hover{
    background-color: var(--bg-color4);
    border: 0.5px var(--bg-color5) solid;
}




.littlethings-cardGroup {
    position: relative;
    background:linear-gradient(to bottom right, #ff7644 47%, #bd3706 53%)
  }
  
    .littlethings-cardGroup:after {
      content: '';
      width:8px;
      height: 10px;
      position: absolute;
      transition: all .15s ease;
      margin-right: 3.3px;
      margin-bottom: 2px;
    }

    .littlethings-cardGroup:before{
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        transition: all .15s ease;
        margin-top: 4px;
        margin-left: 1px;
    }
  
    .littlethings-cardGroup:before {
      top: 0;
      left: 0;
      transform-origin: top left;
      background-color: #ff7644;
      transform: rotate(-45deg) scale(0);
    }
  
    .littlethings-cardGroup:after {
      right: 0;
      bottom: 0;
      transform-origin: bottom right;
      background-color: #bd3706;
      transform: rotate(45deg) scale(0);
    }
  
    .littlethings-card {
      transform: translate(0, 0);
      transition: all .15s ease;
      position: relative;
      z-index: 10;
      
    }
  
  .littlethings-cardGroup:hover .littlethings-card{
    transform: translate(6px, -6px);
  }
  
  .littlethings-cardGroup:hover:before {
    transform: rotate(-45deg) scale(1);
  }
  
  .littlethings-cardGroup:hover:after {
    transform: rotate(46deg) scale(1);
  }


  .description-container {
    position: relative;
    overflow: hidden; /* Hides overflow content */
}

.description {
    line-height: 1.5em; /* Adjust line height as needed */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Limits the text to 3 lines */
    overflow: hidden;
    text-overflow: ellipsis;
}

.show-more {
    display: none; /* Initially hide the button */
}




/* New post layout */






  .littleCardList{
    position: sticky;
    top: 80px;
    max-width: 30%;
    display: inline-block;
  }

  .littleCardDetails {
    max-width: 68%;
    position: relative;
    vertical-align: top;
    display: inline-block;
    margin-left: 2%;
  }
  
  .littleCardTitle {
    cursor: pointer;
    padding: 10px;
    vertical-align: top;
    color: var(--text-color2);
    display: flex;
    border-left:2px solid var(--text-color2);

  }
  .littleCardTitle.selected {
   
    color: var(--text-color1); /* Change text color for better contrast */
    border-left:2px solid var(--text-color4);
 
  }
  
  .littleCardTitle:hover {
    cursor: pointer;
    color: var(--text-color1); 
   
  }
  
  
  
  .littleCard {
    margin-bottom: 50px;
  }
  
  .littleCard img {
    max-width: 100%;
  }






.ender{
    stroke: var(--bg-color5);
}

/* Ripple styling */
.ripple {
  display: none;
    position: fixed; /* Fixed to viewport for full-screen coverage */
      width: 34px; /* Initial size */
      height: 34px; /* Initial size */
      background-color: rgba(192, 37, 37, 0.5); /* Semi-transparent color */
      border-radius: 50%; /* Makes it circular */
      pointer-events: none; /* Prevent blocking interactions */
      z-index: 10;
  }
  
  /* Ripple animation */
  @keyframes ripple-animation {
    0% {
        transform: translate(0, -50%) scale(0); /* Start small */
        opacity: 100%;
        backdrop-filter: saturate(180%) blur(36px);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
         /* Slightly visible at start */
      }
      100% {
        transform: translate(-100vh, -50%) scale(200); /* Scale up to cover viewport */
        opacity: 100%;
        backdrop-filter: saturate(90%) blur(20px);
        -webkit-backdrop-filter: saturate(0%) blur(20px);
         /* Fade out at the end */
      }
  }