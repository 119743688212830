@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@300;400;500;600;700&display=swap');


:root{

  /* Dark Mode Colors */
  --bg-color1: #000000;       /* Black */
  --bg-color2: #1A1A1A;       /* Dark Gray */
  --bg-color3: #202020;       /* Medium Dark Gray */
  --bg-color4: #808080;       /* Gray */

  /* transparent colors */
  --bg-color5: #FFFFFF80;      /* 75% transparent black */
  --bg-color6: #FFFFFF30;     /* 50% transparent black */
  --bg-color7: #FFFFFF10;     /* 25% transparent black */

  --text-color0: #fff;
  --text-color1: #fffffff0;
  --text-color2: #ffffff40;
  --text-color3: #ffffff20;
  --text-color4:#bbbbbb;
  --text-color5:#ffffff80;
  --text-color6:#ffffffd0;
  --text-color7:#ffffff60;
  --text-color8:#ffffff10;

  --shadow-color1:  rgba(0, 0, 0, 0.8);
  --shadow-color2:  rgba(0, 0, 0, 0.3);
  --shadow-color3:  rgba(0, 0, 0, 0.2);

  --gradient-color1: rgba(10, 10, 10, 0.9);
  --gradient-color2: rgba(20, 20, 20, 0.9);
  --gradient-color3: rgba(40, 40, 40, 0.9);
  --gradient-color4: rgba(60, 60, 60, 0.9);
  --gradient-color5: rgba(90, 90, 90, 0.9);

  --bg-location: url('./assets/Location_DarkMode.png');
  --testimonial-filter: invert(0);
  --bg-color1-rgb: 0, 0, 0;
 
}

[data-theme="light"] {
 
  /* Light Mode Colors */
  --bg-color1: #FFFFFF;       /* White (instead of black) */
  --bg-color2: #f0f0f0;       /* Very Light Gray (instead of dark gray) */
  --bg-color3: #E0E0E0;       /* Light Gray (instead of medium dark gray) */
  --bg-color4: #999999;       /* Medium Gray (instead of gray) */

    /* transparent colors */
    --bg-color5: #00000080;      /* 75% transparent white */
    --bg-color6: #00000040;     /* 50% transparent white */
    --bg-color7: #00000010;     /* 25% transparent white */


  --text-color0: #000;
  --text-color1: #000000f0;
  --text-color2: #00000040;
  --text-color3: #00000020;
  --text-color4:#272727;
  --text-color5:#00000080;
  --text-color6:#000000d0;
  --text-color7:#00000060;
  --text-color8:#00000010;

  --shadow-color1:  rgba(255, 255, 255, 0.8);
  --shadow-color2:  rgba(255, 255, 255, 0.3);
  --shadow-color3:  rgba(255, 255, 255, 0.2);

  --gradient-color1: rgba(245, 245, 245, 0.9);
  --gradient-color2: rgba(235, 235, 235, 0.9);
  --gradient-color3: rgba(215, 215, 215, 0.9);
  --gradient-color4: rgba(195, 195, 195, 0.9);
  --gradient-color5: rgba(175, 175, 175, 0.9);

  --bg-location: url('./assets/Location_LightMode.png');
  --testimonial-filter: invert(1);
  --bg-color1-rgb: 255, 255, 255;
  
}


*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'space Grotesk',sans-serif;
  --plyr-color-main: #ff7744f0;
 cursor: url(../src//assets/customCursor.png),auto !important;
 list-style: none;
}

body{
  overflow-y: scroll;
}

button:hover,a:hover, .closeIcon:hover, .closeIcon path:hover,.musicIcon:hover,.musicIcon path:hover,.cardLib:hover,.cardLib *:hover,.backButton *:hover{
  cursor: url(../src//assets/hover.png),auto !important;
}
a *:hover,button *:hover,a.svg:hover,a span:hover,div.writingItem, div.writingItem *{
  cursor: url(../src//assets/hover.png),auto !important;
}

figure .plyr{
  border-radius: 0.5rem ;
}

.home, .works, .writings, .otherActivities,.projectDetails,.writingDetails,.PageNotFound,.DesignLibrary{
  display: flex;
  height: auto;
  justify-content: center;
  max-width: min(864px, 100vw);
  margin: auto;

}

p{
  font-size:16px;
  font-weight:400;
  color:var(--text-color4);
  line-height:1.6rem;
  white-space: pre-line;  
}

h1{
  font-weight:600;
color:var(--text-color1);
line-height:1.6rem;
}

h2{
  font-weight:600;
  color:var(--text-color1);
  line-height:1.6rem;
}

h3{
  font-weight:600;
  color:var(--text-color1);
  line-height:1.6rem;
}
h4{
  font-size: medium;
  font-weight:500;
  color:var(--text-color6);
  line-height:1.6rem;
}


h6{
  font-size:16px;
  font-weight:400;
  color: var(--text-color6);
}

a {
  text-decoration: none;
  text-decoration-color :initial;
  color: initial;
}

hr{
  margin: 1rem 0px;
  opacity: 20%;
  z-index: -1;
}

hr.dot::before{
  content: "...";
}

blockquote{
  color: var(--text-color6);
  border-left: var(--text-color1) solid 4px;
  padding: 1rem;
  background-color: var(--text-color3);
}

code{
background-color: red;
}





.HeaderFixed{
  background-color: transparent;
  display:flex;
  flex-direction: column;
  gap:1rem;
  margin-bottom: 1rem;
  z-index: 100;
  display: none;
}
.HeaderSticky{
  background-color: var(--bg-color1);
  border-bottom: 2px var(--text-color3) solid;
  height: auto;
  display:flex;
  gap:1rem;
  align-items:center;
  position: sticky;
  top: 0%;
  z-index: 100;
  padding: 0.5rem 0rem;
  display: none;
  
}

.HeaderSticky h2{
  font-size: 16px;
}

.back{
    display:none;
}

.primaryBtn{
  background-color: transparent;
  
  display:flex;
  flex-direction:row;
  align-items:center;
  gap:0.5rem;
  padding:0.5rem 1rem;
  border-radius: 2rem;
  border: 1px  solid transparent;
  color: var( --text-color0);
  margin:0.5rem 1rem;
  align-self: center;
}

.primaryBtn:hover{
  background-color: var(--text-color3);

}

.primaryBtn:hover svg{
  color: var(--text-color1)  !important;
}

.fillBtn{
  background-color: var(--text-color3);
  
  display:flex;
  flex-direction:row;
  align-items:center;
  gap:0.5rem;
  padding:0.5rem 1rem;
  border-radius: 2rem;
  border: 1px  solid transparent;
  color: var( --text-color0);
  margin:0.5rem 1rem;
  align-self: center;
}

.fillBtn:hover{
  background-color: #ff7744;
  
  display:flex;
  flex-direction:row;
  align-items:center;
  gap:0.5rem;
  border: 1px  solid transparent;
  color: var( --text-color0);
  margin:0.5rem 1rem;
  align-self: center;
}

.fillBtn:hover svg{
  fill: var(--text-color1) !important;
}

.backButton{
  background-color: transparent;
      display:flex;
      flex-direction:row;
      align-items:center;
      gap:0.5rem;
      padding:0.2rem 0.5rem;
      border-radius: 0.25rem;
      border: 1px  solid var(--text-color5);
    
}

.backButton:hover{
  background-color: var(--text-color2);
}

.backButton:hover svg{
  color: var(--text-color1);
}
body{
background-color: var(--bg-color1) !important;
}

body .spline-watermark{
  display: none;
}


/* Scrollbar */
body::-webkit-scrollbar {
  width: 0.6rem;            /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
background: var(--bg-color1);        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
background-color: var(--bg-color2);  /* color of the scroll thumb */
border-radius: 10px; 
height:0.6rem; 
    /* roundness of the scroll thumb */
}
body::-webkit-scrollbar-button{
scrollbar-width:0.6rem; 
}
