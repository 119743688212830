

.backIcon{
    width: 1.2rem ;
    height:1.2rem;
    color: var(--text-color5);
}

/*---------------*/

