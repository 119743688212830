/* ::-webkit-scrollbar {
    width: 0px;
  } */
  
  .container {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding: 2rem 0rem;
    
  }
  
  .Tcard {
    filter: var(--testimonial-filter);
    flex-shrink: 0;
    width: 44%;
    height: 14rem;
    background-color: #171717;
    border-radius: 1rem;
    margin-left: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0.5rem;
     box-shadow: 1px 1px 0px #343434,
                2px 2px 0px #343434,
                3px 3px 0px #343434,
                4px 4px 0px #343434,
                5px 5px 0px #343434,
                6px 6px 0px #343434
                 
    
                
                  
               

  }
  .container::-webkit-scrollbar {
    height: 0.4rem;            /* width of the entire scrollbar */
}

.container::-webkit-scrollbar-track {
  background: rgba(255, 166, 0, 0);        /* color of the tracking area */
}

.container::-webkit-scrollbar-thumb {
  background-color: var(--bg-color2);   /* color of the scroll thumb */
  border-radius: 20px; 
  height:1rem; 
      /* roundness of the scroll thumb */
}
.container::-webkit-scrollbar-button{
  scrollbar-width:1rem; 
}



.Tcard .glow-wrap{
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 100;
}

.Tcard .glow{
  margin-left: 50%;
  display: block;
  position:absolute;
  height: 250%;
  width: 60%;
  top: 0;
 
}
.Tcard:hover .glow{
  background: linear-gradient( to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100%);
  animation-duration: 1s;
  animation-name: glowEffect;
  animation-iteration-count: infinite;
  animation-direction: alternate;

}

@keyframes glowEffect {
  0% {
    width: 50%;
    opacity: 0.2;
    transform: rotate(20deg) translate(200%, -20%);
    }
  100% {
    width: 50%;
    opacity:0;
    transform: rotate(20deg) translate(-200%, -20%);
  }

}