.projectsList{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 3rem;
}

.card{
    max-width: min(864px, 100vw);
    height: 12rem;
    background-color: var(--bg-color2);
    border-radius: 0.5rem;

    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: box-shadow 0.3s ease;
}

.card:hover{
    background-color: var(--bg-color2);
}



.cardGroup {
  border-radius: 0.54rem;
    margin-bottom: 1rem;
    position: relative;
    background:linear-gradient(to bottom right, #ff7644 47%, #bd3706 53%)
  }
  
    .cardGroup:after {
      content: '';
      width:8px;
      height: 10px;
      position: absolute;
      transition: all .15s ease;
      margin-right: 3.3px;
      margin-bottom: 2px;
    }

    .cardGroup:before{
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        transition: all .15s ease;
        margin-top: 4px;
        margin-left: 1px;
    }
  
    .cardGroup:before {
      top: 0;
      left: 0;
      transform-origin: top left;
      background-color: #ff7644;
      transform: rotate(-45deg) scale(0);
    }
  
    .cardGroup:after {
      right: 0;
      bottom: 0;
      transform-origin: bottom right;
      background-color: #bd3706;
      transform: rotate(45deg) scale(0);
    }
  
    .card {

      transform: translate(0, 0);
      transition: all .15s ease;
      position: relative;
      z-index: 10;
    }
  
  .cardGroup:hover .card {
    transform: translate(6px, -6px);
  }
  
  .cardGroup:hover:before {
    transform: rotate(-45deg) scale(1);
  }
  
  .cardGroup:hover:after {
    transform: rotate(46deg) scale(1);
  }
