



/*---------------  Intro--------------- */
.intro{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}





/*---------------  Past Expereience--------------- */

.pastExpereince{ 
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 3rem;
}
.experience-row{
    flex:1;
    display:flex;
    flex-direction:column;
    gap:1rem;
}

.expereince-lineitems{
    flex:1;
    display:flex;
    flex-direction:row;
    align-items: center;
    gap:0.5rem;
}

.desgPeriod{
    display:flex;
    flex-direction:row;
    gap:8px
}



/*--------------- social Media--------------- */

.socialMedia{ 
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.social-row{
    flex:1;
    display:flex;
    flex-direction:column;
    gap:1rem;
}

.social-lineitems{
    flex:1;
    display:flex;
    flex-direction:row;
    align-items: center;
    gap:0.5rem;
}



/*------------Location------------*/

.location{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 3rem;
}

.locationImg{
    width: 100%;
    height: 16rem;
    border-radius: 1rem;
    display: block;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: initial;
    background-image: var(--bg-location);
}




/* center the blockquote in the page */
.blockquote-wrapper {
    display: flex;
    font-style: italic;
    margin-top: 2rem;
 }
 
 /* Blockquote main style */
 .blockquote {
     position: relative;
     align-self: center;
     background-color: var(--bg-color2);
     border-radius: 0.5rem;
     padding-top: 0%;
     margin-top: 0.4rem;
 }
 
 /* Blockquote header */
 .blockquote p {
     position: relative; /* for pseudos */
     color: var(--text-color4);
     font-weight: normal;

     padding: 0.5rem 1rem;
     line-height: 1.6rem;
 }
 
 .blockquote span{
    color: var(--text-color4);
    font-weight: 700;
 }
 
 
 /* increase header size after 600px */
 @media all and (min-width: 600px) {
     .blockquote p {
         line-height: 1.6rem;
    }
 
 }



 .highlighter{
    background: linear-gradient(to top, #ff7744c0 0%, #ff7644c0 24%, transparent 25%, transparent 100%);
    -webkit-transition: new 1000ms linear;
    -ms-transition: new 1000ms linear;
    transition: new 1000ms linear;
 }

 .highlighter:hover{
    background: linear-gradient(to top, #ff7744 0%, #ff7744 100%);
    color: azure;
 }

 

 .react_lightgallery_item{
    width: 100%;
    height: auto;
    aspect-ratio: 2;
 }

 .react_lightgallery_item:hover{
    cursor: url('..//assets/hover.png'),auto !important;
}




/*------------Darkmode Toggle button css------------*/


  


  .knobs,.layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .button {
    position: relative;
    top: 50%;
    width: 4.4rem;
    height: 2.4rem;
    margin: -20px auto 0 auto;
    overflow: hidden;
  }
  
  .button.b2 {
    border-radius: 40px;
     border: 1px solid #00000020;
     
  }
  
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  .knobs {
    z-index: 2;
  }
  
  .layer {
    width: 100%;
    background-color: var(--text-color3);
    transition: 0.3s ease all;
    z-index: 1;
  }
  
  
  /* Button 18 */
  #button-18 .knobs:before {
    content: "🌚";
    position: absolute;
    top: 4px;
    left: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    border-radius: 40px;
  }


  #button-18 .knobs span {
    content: "🌚";
    position: absolute;
    top: 4px;
    left: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    background-color:#ff7744;
    border-radius: 40px;
  }

  
  #button-18 .knobs:before {
    top: 46%;
    left: 9px;
    width: 20px;
    height: 10px;
    margin-top: -5px;
    z-index: 2;
  }
  
  #button-18 .knobs span {
    width: 44%;
    height: 80%;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    z-index: 1;
  }
  
  #button-18 .checkbox:active + .knobs:before {
    width: 46px;
    height: 4px;
    color: transparent;
    margin-top: -2px;
    transition: 0.3s ease all;
    overflow: hidden;
  }
  
  #button-18 .checkbox:active + .knobs span {
    width: 58px;
  }
  
  #button-18 .checkbox:checked:active + .knobs:before {
    left: auto;
    right: 10px;
  }
  
  #button-18 .checkbox:checked:active + .knobs span {
    margin-left: -38px;
  }
  
  #button-18 .checkbox:checked + .knobs:before {
    content: "🌞";
    left: 40px;
  }
  
  #button-18 .checkbox:checked + .knobs span {
    left: 34px;
  }
  
  #button-18 .checkbox:checked ~ .layer {
    background-color: #fff;
  }

  .svg-container {
    margin-top: 10rem;
    display: inline-table;
    width: 100%;
    height: 150px;
    resize: both;
   }
   
   .svg-container svg {
    width: 90%;
    max-width: 600px;
   }

   .heroContainer{
    display:flex;
    flex-direction:row;
    border-radius:1rem;
    gap:3rem;
    align-items: center;
    margin-top: 2rem;
   }

   /* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
  .heroContainer {
      flex-direction: column; /* Stack elements vertically */
  }

  img {
      order: 1; /* Image comes first */
  }

  .intro {
      order: 2; /* Intro comes second */
  }
}
         