/* Popup style */
.popup-box {
  backdrop-filter: blur(0.5rem);
    position: fixed;
    background: #000000a0;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    margin: auto;
    z-index: 1000;
    display: flex;
    align-items: center;
    height: 100vh;
    overflow-y: hidden;
  }
   
  .box {
    width: 90%;
    height: fit-content;
    margin: 0 auto;
    max-width: 576px;
    background: var(--bg-color2);
    border-radius: 0.5rem;
    padding: 2rem 2rem;
    overflow: auto;
    border-top: 0.2rem solid #ff7744f0;
    display: flex;
    flex-direction: column;
   
    
  }

  input{
    height: 3rem;
    border-radius: 4px;
    background: var(--bg-color1);
    border: 1px solid var(--text-color3);
    padding: 0.5rem;
    color: var(--text-color0);
  }
  textarea{

    border-radius: 4px;
    background: var(--bg-color1);
    border: 1px solid var(--text-color3);
    padding: 0.5rem;
    color: var(--text-color0);
    resize: none;
  }
   
  .msgSuccess{
    display: none;
  }