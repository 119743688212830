.grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap:0.4rem
}
.cardLib{
    padding: 0.4rem ;
    height: 5rem;
    display: flex;
    gap: 0.8rem;
    position: relative;
   
}

.cardLib:hover{
    background-color: var(--text-color3);
    border-radius: 0.5rem;
}
.ItemName{
    width: 100%;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
   margin-top: 4px;
   line-height:1.2rem;   
}


@media only screen and (max-width: 600px) {
    .grid{
        grid-template-columns: 1fr;
    }
  }

.tabButton{
    border: 0;
    color: var(--text-color4);
    display: flex;
    align-items: center;
    height: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 4rem;
    border: 1px solid var(--bg-color3);
    background-color: var(--bg-color1);
}
.tabButton:hover{
    background-color: var(--bg-color3);
}

.tabButton-active {
    border: 0;
    color: var(--text-color1);
    display: flex;
    align-items: center;
    height: 2rem;
    padding: 0.5rem 1rem;
    border-radius:4px;
    background-color: var(--bg-color3);
    border: 1px solid var(--text-color1);
    border-radius: 4rem;
} 

.myFav{
    font-size:12px;
    background-color: #ff7744f0;
    border-radius: 4px;
    padding: 1px 4px;
    margin-left: 4px;

}


.HeaderFixed::-webkit-scrollbar {
    display: none;        /* width of the entire scrollbar */
}