.navbar {
    height: 80px;
    
    
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background-color: none;
}

.nav-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: sticky;
    top: 0;
    transition: 850ms;
    padding: 0.5rem;
    z-index: 10;

  
}
.nav-menu::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(270deg, 
        var(--bg-color1) 0%, 
        var(--bg-color6) 50%, 
        var(--bg-color1) 100%
    );
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
}

.nav-text a {
    color: var(--text-color0);
    font-size: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 0.5rem;
}

.nav-text a:hover{
    background-color: var(--text-color2);
    color: var( --text-color0);
}

.nav-text-selected{
    list-style-type: none;
}

.nav-text-selected a{
    text-decoration: none;
    
    font-size: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    background-color: var(--bg-color5);
    color: var( --text-color0);
    margin-bottom: 0.5rem;

}


.logo{
    align-items: center;
    gap: 0.4rem;
    flex: 1;
    display: flex;
    color: var(--text-color0) ;
    padding: 4px 8px;
    margin-left: 1rem;
}

/* ------------Mobile CSS Starts -------------------- */

.nav-mobile-text{
    text-decoration: none;
    font-size: 14px;
    height: 100%;
    flex-direction: row;

    align-items: center;
    border-radius: 4px;
    padding: 8px;
    color: var( --text-color0);
    display: flex;
    flex: 1;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: nowrap;
}

.nav-menu-mobile{
    background-color: var(--bg-color2);
    height: 4rem;
    width: 100vw;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    position: fixed;
    bottom: 0;
    transition: 850ms;
    padding: 0.5rem;
    z-index: 100;
}

.nav-mobile-text-selected{
    text-decoration: none;
    font-size: 14px;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    background-color: var(--text-color2);
    color: var( --text-color0);

    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: nowrap;
    list-style-type: none;
    height: 100%;

}

.nav-menu-mobile-items{
    width: 100%;
    flex: 1;
    display: flex;
    height: 100%;
    
}

.nav-mobile-text-selected a{
    text-decoration: none;
    color: var( --text-color0);
    
}

.nav-mobile-text a{
    text-decoration: none;
    color: var( --text-color0);

}

.emoji{
    font-family: Apple Color Emoji,Segoe UI Emoji,Noto Color Emoji,Android Emoji,EmojiSymbols,EmojiOne Mozilla,Twemoji Mozilla,Segoe UI Symbol;
    font-weight: 400;
}

.nav_Icons{
    color:var(--text-color4);
}

.musicIcon{
    font-size:1.5rem;
    width: 3rem;
    height: 3rem;
    padding: 0.8rem;
    border-radius: 4px;
}
.musicIcon:hover{
    color: #ff7744f0;
    background-color: rgba(0, 0, 0, 0.081);
}


.albumCover { 
    animation: rotation 10s infinite linear;
    animation-play-state: paused;
 }

@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


/* -------- New Code for Menu List ------------- */
#menu {
    color: var(--text-color1);
    position: fixed;
    top: 94vh; /* Keeps it near the bottom of the page */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for exact centering */
    z-index: 1000;
    margin-bottom: 10rem; /* Adjust spacing from the bottom */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

#menu .content {
    position: relative;
    background: var(--bg-color7);    
    backdrop-filter: saturate(180%) blur(36px);
    -webkit-backdrop-filter: saturate(180%) blur(36px);
    border-radius: 2rem;
    overflow: hidden;
    border: 1px solid var(--bg-color6) ;
}

#menu .content .nav-menu-items{
    display: flex;
    border-radius: 2rem;
    padding: 2px;

    border-radius: 2rem;
}

#menu .content .nav-menu-items .item{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0.5rem 0.75rem;
    border-radius: 2rem;
    cursor: pointer;
    z-index:2;
    transition: 0.5s ease;
    .nav_Icons {
        color: var(--text-color1);
    }
    span{
        color: var(--text-color1);
    }

   
}

#menu .content .nav-menu-items .item:hover {

    .nav_Icons {
        color: var(--bg-color5);
    }
    span{   
        color: var(--bg-color5);
    }
}




#menu .content .nav-menu-items .item a {
    align-items: center;
    display: flex;
    vertical-align: middle;
    color: var(--text-color1);
    svg {
        width: 16px;
        height: 16px;
    }
}

#menu .content .nav-menu-items .item a span{
    margin-left: 4px;
}





.icon-container{
    position: relative;
    display: inline-block; /* Ensures proper alignment */
    cursor: pointer; /* Pointer cursor on hover */
}

/* Pseudo-element for circular background */
.icon-container::before {
    cursor: pointer;
    content: ""; /* Empty content for the circle */
    position: absolute;
    top: 40%;
    left: 50%;
    width: 0; /* Start with no size */
    height: 0; /* Start with no size */
    background-color: #ff76442f;
    ; /* Semi-transparent white */
    border-radius: 50%; /* Makes it circular */
   /* Smooth scaling and opacity transition */
  }
  
  /* Hover effect for the container */
  .icon-container:hover::before {
    width: 44px; /* Final width of the circle */
    height: 44px; /* Final height of the circle */
    transform: translate(-50%, -50%) scale(1); /* Scale up to full size */
  }


.darkModeIcon:hover{
    background-color: var(--text-color3) !important;
    div,svg,path{
    cursor: pointer !important;
   }

}



   


#menu .content #pill{

    height: 36px;
    position: absolute;
    left: 0px;


    transition: left .5s ease, width .5s ease;
    z-index: 0;
   
    
   
    /* Button shape and spacing */
    border: 1px solid var(--bg-color6);
    border-radius: 100px;
    padding: 10px 20px;

    /* Text styling */
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Space between text and icon if needed */

    background: linear-gradient(180deg, var(--gradient-color4) 0, var(--gradient-color3) 61%, var(--gradient-color4) 100%);
    border: 1px solid var(--text-color3);
    border-radius: 2rem;
    border-radius: 100px;
    box-shadow: .5px .5px 1px var(--shadow-color1), 0 1px 2px var(--shadow-color2), 0 2px 4px var(--shadow-color3);
}


.gradient-blur {
    position: fixed;
    width: 100vw; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    background: transparent;
    backdrop-filter: blur(36px);
    mask-image: linear-gradient(to bottom, black, rgb(0 0 0 / 60%), #00000000);
    -webkit-mask-image: linear-gradient(to bottom, black, rgb(0 0 0 / 60%), #00000000);
    z-index: 1;
  }


  .bottom-gradient-blur{
    position: fixed;
    width: 100vw; /* Adjust as needed */
    height: 76px; /* Adjust as needed */
    z-index: 1;
    background: linear-gradient(180deg, 
        rgba(var(--bg-color1-rgb), 0) 0%, 
        rgba(var(--bg-color1-rgb), 0) 1%, 
        rgba(var(--bg-color1-rgb), 0.3) 30%, 
        rgba(var(--bg-color1-rgb), 1) 100%); 
    bottom: 0;
}

