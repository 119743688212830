.tools{ 
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 0.5rem;
}
.tool-row{
    flex:1;
    display:flex;
    flex-direction:column;
    gap:1rem;
}

.tool-lineitems{
    flex:1;
    display:flex;
    flex-direction:row;
    align-items: center;
    gap:0.5rem;
}

.toolLogo{
    width: 1.6rem;
    border-radius: 0.2rem;
    max-height: 24px;
    max-width: 24px;
    aspect-ratio: 1;
    padding: 0.1rem;
}

.CertLogo{
    width: 2rem;
    border-radius: 4px;
    max-height: 42px;
    max-width: 42px;
    aspect-ratio: 1;
    padding: 0.1rem;
}


.resourcesButton{
    display: flex;
    height: 3rem;
    border-radius: 2rem;
    border: 0rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    align-items: center;
    padding: 0.2rem 0.5rem 0.2rem 1rem;
    font-size: medium;
    font-weight: 800;
    color: var(--text-color0);
    justify-content: space-between;
    width: 100%;

    background: 
    linear-gradient(180deg,var(--gradient-color4) 0%,var(--gradient-color3) 21%,var(--gradient-color4) 100%);
    
    box-shadow: 
    0.5px 0.5px 1px var(--shadow-color1), /* Outer shadow for surface cut */
    0px 1px 2px var(--shadow-color2),     /* Mid-level shadow */
    0px 2px 4px var(--shadow-color3);

    /* Button shape and spacing */
    border: 1px solid var(--text-color3);
}

.resourcesButton:hover{
    background-color:var(--text-color2);
    backdrop-filter: blur(0px);
}


.LibraryHeroCardSubtext{

    font-size:14px;
    line-height:18px;
     margin-top:8px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical; 
      overflow: hidden;
      color: var(--text-color6);

}
@keyframes floatDiagonal {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-10px, -10px);
    }
    100% {
      transform: translate(0, 0);
    }
  }